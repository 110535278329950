import { Fragment } from 'react';
import dynamic from 'next/dynamic';

// Dynamic imports
const BannerMain = dynamic(() => import('../src/components/molecules/Banner/BannerMain'));
const GameSearchComponent = dynamic(() =>
	import('../src/components/molecules/GameSearchComponent')
);
const GameCategoryComponent = dynamic(() =>
	import('../src/components/molecules/GameCategoryComponent')
);
const GameLotteryResultComponent = dynamic(() =>
	import('../src/components/molecules/GameLotteryResultComponent')
);
const AnnouncementComponent = dynamic(() =>
	import('../src/components/molecules/AnnouncementComponent')
);
const AuthSectionComponent = dynamic(() =>
	import('../src/components/molecules/AuthSectionComponent')
);
const JackpotProgressComponent = dynamic(() =>
	import('../src/components/molecules/JackpotProgressComponent')
);
const RealtimeTransactionComponent = dynamic(() =>
	import('../src/components/molecules/RealtimeTransactionComponent')
);
const GamepopularComponent2 = dynamic(() =>
	import('../src/components/molecules/GamepopularComponent2')
);
const GameRecommendedComponent2 = dynamic(() =>
	import('../src/components/molecules/GameRecommendedComponent2')
);
const GameProviderComponent2 = dynamic(() =>
	import('../src/components/molecules/GameProviderComponent2')
);

const Home = () => {
	const brandSectionOrder = [
		'announcement',
		'authSection',
		'gamecategory',
		'jackpotprogress',
		'gamesearch',
		'gameprovider',
		'gamelotteryresult',
		'gamePopular',
		'gamerecommended',
		'realtimetransaction'
	];

	const sectionOrderComponent = brandSectionOrder.map((item, index) => {
		const sectionSlideMode = index % 2 === 0 ? 'fromLeft' : 'fromRight';

		let component;
		switch (item) {
			case 'banner':
				component = <BannerMain slideMode={sectionSlideMode} />;
				break;
			case 'authSection':
				component = <AuthSectionComponent slideMode={sectionSlideMode} className="mb-section" />;
				break;
			case 'announcement':
				component = <AnnouncementComponent slideMode={sectionSlideMode} />;
				break;
			case 'gamecategory':
				component = <GameCategoryComponent slideMode={sectionSlideMode} loop />;
				break;
			case 'gamesearch':
				component = (
					<Fragment>
						<div className="hidden md:block">
							<GameSearchComponent slideMode={sectionSlideMode} />
						</div>
					</Fragment>
				);
				break;
			case 'gameprovider':
				component = <GameProviderComponent2 slideMode={sectionSlideMode} loop />;
				break;
			case 'gamelotteryresult':
				component = <GameLotteryResultComponent slideMode={sectionSlideMode} showTitle />;
				break;
			case 'gamerecommended':
				component = <GameRecommendedComponent2 slideMode={sectionSlideMode} showTitle />;
				break;
			case 'gamePopular':
				component = <GamepopularComponent2 slideMode={sectionSlideMode} showTitle />;
				break;
			case 'realtimetransaction':
				component = <RealtimeTransactionComponent />;
				break;
			case 'jackpotprogress':
				component = <JackpotProgressComponent />;
				break;
			default:
				component = null;
				break;
		}

		return component ? <Fragment key={index}>{component}</Fragment> : null;
	});

	return <Fragment>{sectionOrderComponent}</Fragment>;
};

export default Home;
